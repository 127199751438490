import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import AuthApi from "./api/authApi"; // Adjust the import path according to your project structure

// Replace this firebaseConfig object with the configurations for the project you created on your firebase console. 
const firebaseConfig = {
    apiKey: "AIzaSyC9egGTkFdYumJbcxPqzcqgMuQJTbcjmh8",
    authDomain: "react-push-notification-c4aef.firebaseapp.com",
    projectId: "react-push-notification-c4aef",
    storageBucket: "react-push-notification-c4aef.appspot.com",
    messagingSenderId: "850207337270",
    appId: "1:850207337270:web:3949ffd65013ac9740e197"
};

initializeApp(firebaseConfig);

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

//const messaging = getMessaging();
const api = new AuthApi();


// Request permission for notifications
/* export const requestForToken = async () => {
    try {
        const token = await getToken(messaging, { vapidKey: 'BE9wo6eGwLgs9rTVNc5dWrMDPL3jQpxPHSWyqr_PtmAGNx29XWxvl1S0Ykk_7FmyWyRBvE9BufnQITbZhpwZM_s' });
        if (token) {
            console.log('FCM Token:', token);
            // You can send this token to your server to save it
        } else {
            console.log('No registration token available. Request permission to generate one.');
        }
    } catch (error) {
        console.error('An error occurred while retrieving token.', error);
    }
}; */

export const requestForToken = async () => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            const currentToken = await getToken(messaging, {
                vapidKey: 'BE9wo6eGwLgs9rTVNc5dWrMDPL3jQpxPHSWyqr_PtmAGNx29XWxvl1S0Ykk_7FmyWyRBvE9BufnQITbZhpwZM_s'
            });
            if (currentToken) {
                console.log('current token for client: ', currentToken);
                // Store the token in localStorage
                //localStorage.setItem('firebaseToken', currentToken);
                return currentToken
            } else {
                console.log('No registration token available. Request permission to generate one.');
            }
        } else if (permission === 'denied') {
            console.warn('Notifications are denied by the user.');
        }
    } catch (error) {
        console.error('An error occurred while retrieving token.', error);
    }
};


/* export const requestForToken = async (email) => {
    try {
      const permission = await Notification.requestPermission();
      
      if (permission === 'granted') {
        const currentToken = await getToken(messaging, { vapidKey: 'BE9wo6eGwLgs9rTVNc5dWrMDPL3jQpxPHSWyqr_PtmAGNx29XWxvl1S0Ykk_7FmyWyRBvE9BufnQITbZhpwZM_s' });
        console.log('FCM Token:', currentToken);
        // Send the token to the server, etc.

        if (currentToken) {
            console.log('current token for client: ', currentToken);
            // Perform any other necessary action with the token
            const userData = {
                userEmail: email,
                token: currentToken,
            };

            const res = await api.updateDeviceToken(userData);

            console.log("res=========", res);

            if (res.success) {
                // Handle success response
                console.log('Token updated successfully:', res.data);
            } else {
                // Handle error response
                console.error('Failed to update token:', res.msg);
            }
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
        }

      } else if (permission === 'denied') {
        console.warn('Notifications are denied by the user.');
      } else if (permission === 'default') {
        console.warn('User dismissed the notification permission dialog.');
      }
    } catch (error) {
      if (error.code === 'messaging/permission-blocked') {
        console.error('Permission blocked. Ask the user to enable notifications manually.');
      } else {
        console.error('An error occurred while retrieving token.', error);
      }
    }
  }; */

//requestPermission();

// Handle incoming messages
/* onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // Handle notification payload
}); */

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log("payload", payload);
            resolve(payload);
        });
});



/* export const requestForToken = async (email) => {
    try {
        console.log("requestForToken=========", email);

        const currentToken = await getToken(messaging, { vapidKey: 'BE9wo6eGwLgs9rTVNc5dWrMDPL3jQpxPHSWyqr_PtmAGNx29XWxvl1S0Ykk_7FmyWyRBvE9BufnQITbZhpwZM_s' });

        if (currentToken) {
            console.log('current token for client: ', currentToken);
            // Perform any other necessary action with the token
            const userData = {
                userEmail: email,
                token: currentToken,
            };

            const res = await api.updateDeviceToken(userData);

            console.log("res=========", res);

            if (res.success) {
                // Handle success response
                console.log('Token updated successfully:', res.data);
            } else {
                // Handle error response
                console.error('Failed to update token:', res.msg);
            }
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
        }
    } catch (err) {
        console.log('An error occurred while retrieving token: ', err);
    }
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log("payload", payload);
            resolve(payload);
        });
}); */